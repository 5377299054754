'use strict';

/**
 * Represents a book.
 * @constructor
 * @param {object} opts
 * @param {string} opts.baseUrl - The webservice's base url
 * @param {string} opts.apiUser - The webservice's api user
 * @param {string} opts.apiToken - The webservice's api token: md5('L3tXL4f53julMGkISeSO' + AGENT_ID)
 * @param {SepaRequest.Agent} opts.agent - Current agents information
 *
 * @property {SepaRequest.mandate} mandate - {@link SepaRequest.mandate}
 * @property {SepaRequest.export} export - {@link SepaRequest.export}
 */
function SepaRequest(opts) {

    /**
     * Description of the agent that uses the sepa module
     *
     * @typedef {object} SepaRequest.Agent
     * @property {number} id - The agent's unique identifier
     * @property {string} creditorIdentifier - The agent's creditor identifier
     * @property {string} name - The name which will be used for this agent
     * @property {string} street - The street which will be used for this agent
     * @property {string} zip - The zip code which will be used for this agent
     * @property {string} city - The city which will be used for this agent
     */

    const OPTIONS = {},
        mandate = require('./mandate'),
        sepaExport = require('./export');

    init.bind(this)(opts);

    function init(opts) {
        if (!areRequiredParametersSet(opts)) {
            throw new Error('missing required options');
        }

        Object.keys(opts).forEach(key => {
            OPTIONS[key] = opts[key];
        });
    }

    function areRequiredParametersSet(options) {
        return !(!options.baseUrl || !options.agent || !options.agent.id || !options.agent.name ||
            !options.agent.street || !options.agent.zip || !options.agent.city || !options.apiUser || !options.apiToken);
    }

    return {
        mandate: mandate(OPTIONS),
        export: sepaExport(OPTIONS)
    };
}

module.exports = SepaRequest;
