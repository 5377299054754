'use strict';

require('whatwg-fetch');
require('es6-promise').polyfill();

/**
 * Enum specifying export types
 * @enum {number}
 * @readonly
 * @memberOf SepaRequest.sepaExport
 */
const EXPORT_TYPES = {

    /**
     * The export is a debit (Bankeinzug)
     */
    DIRECT_DEBIT: 1,

    /**
     * The mandate is a credit (Auszahlung
     */
    DIRECT_CREDIT: 2
};

/**
 * Methods to interact with the SEPA api's export endpoint
 *
 * @namespace export
 * @memberOf SepaRequest
 * @param {object} options - WIll automatically be applied
 *
 * @return {object}
 */
function sepaExport(options) {

    /**
     * A collection of exports
     *
     * @typedef {object} SepaRequest.export.ExportCollection
     * @property {object} links - HATEOAS links
     * @property {object} links.self
     * @property {string} links.self.href - Link to this document
     * @property {object} links.first
     * @property {string} links.first.href - Link to the first page of a paginated response
     * @property {object} links.last
     * @property {string} links.last.href - Link to the last page of a paginated response
     * @property {object} _embedded
     * @property {object[]} _embedded.export
     * @property {number} _embedded.export.id - This export's unique identifier
     * @property {number} _embedded.export.agentId - The agent's unique ID that created this export. See {@link SepaRequest.Agent}
     * @property {number} _embedded.export.username - The user that created this export
     * @property {string} _embedded.export.createdAt - Date this export was created at, format "YYYY-MM-DD"
     * @property {string|boolean} _embedded.export.exportedAt - Date "YYYY-MM-DD" if the export was already exported, otherwise false
     * @property {number} _embedded.export.itemCount - The amount of transactions in this export
     * @property {object} _embedded.export._links - HATEOAS links
     * @property {object} _embedded.export._links.self
     * @property {string} _embedded.export._links.self.href - Link to this document
     * @property {number} _embedded.page_count - How many pages there are for a paginated result
     * @property {number} _embedded.page_size - How many mandates per page are returned
     * @property {number} _embedded.total_items -  The total amount of mandates that are available
     * @property {number} _embedded.page - The page this document referes to
     */

    /**
     * An export as returned from the webservice
     *
     * @typedef {object} SepaRequest.export.Export
     * @property {string} id - This export's unique identifier
     * @property {number} agentId - The agent's unique ID that created this export. See {@link SepaRequest.Agent}
     * @property {number} username - The user that created this export
     * @property {string} createdAt - Date this export was created at, format "YYYY-MM-DD"
     * @property {string|boolean} exportedAt - Date "YYYY-MM-DD" if the export was already exported, otherwise false
     * @property {number} itemCount - The amount of transactions in this export
     * @property {SepaRequest.export.Transaction[]} items - Collection of transactions on this export
     * @property {object} _links - HATEOAS links
     * @property {object} _links.self
     * @property {string} _links.self.href - Link to this document
     */

    /**
     * An export transaction
     *
     * @typedef {object} SepaRequest.export.Transaction
     * @property {string} mandateId - The mandate's unique ID
     * @property {string} iban - The mandate's IBAN
     * @property {string} bic - The mandate's BIC
     * @property {string} accountHolder - The mandate owner's name
     * @property {string} subject - Transaction subject
     * @property {string} amount - Transaction amount
     * @property {string} uniqueMandateReference - The mandate's unique reference
     */

    const helpers = require('./helpers'),
        isNaN = require('is-nan'),
        ROUTES = {
            all: 'export',
            one: 'export/:exportId',
            xml: 'export/:exportId/xml?iban=:iban&accountHolder=:accountHolder',
            csv: 'export/:exportId/csv',
            status: 'export/:exportId/status/:statusId'
        };

    /**
     * Fetches a paginated list of all mandates
     *
     * @memberOf SepaRequest.export
     * @param {number} [page=1] - Pagination page
     *
     * @return {Promise.<SepaRequest.export.ExportCollection|Error>}
     */
    function fetchAll(page = 1) {
        var url = helpers.buildUrl(options.baseUrl, ROUTES.all);
        if (page !== 1) {
            url += '?page=' + page;
        }

        return fetch(url, helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Fetches a single export by given id
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - Export ID to fetch
     *
     * @return {Promise.<SepaRequest.export.Export|Error>}
     */
    function fetchOne(exportId) {
        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {exportId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Creates a new export, optionally with a collection of transactions
     *
     * @memberOf SepaRequest.export
     * @param {SepaRequest.sepaExport.EXPORT_TYPES} typeId - Defines whether the export is a debit or a credit
     * @param {object[]} [mandates = []] - Customer data for which a new mandates is being created
     * @param {number} mandates.mandateId - The mandate's unique ID
     * @param {string} mandates.subject - The subject for this transaction
     * @param {string|number} mandates.amount - The amount of this transaction
     * @param {boolean} [isExported = false] - Set the export as already
     *
     * @return {Promise.<SepaRequest.export.Export|Error>}
     */
    function create(typeId, mandates = [], isExported = false) {
        let rejectMessages = validateMandates(mandates),
            body = {},
            requestOptions = helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'POST');

        if (rejectMessages.length) {
            return Promise.reject(new Error(rejectMessages.join(', ')));
        }

        if (!typeId) {
            return Promise.reject(new Error('Missing type_id'));
        }

        if (typeId !== EXPORT_TYPES.DIRECT_CREDIT && typeId !== EXPORT_TYPES.DIRECT_DEBIT) {
            return Promise.reject(new Error('Invalid type_id'));
        }


        body.type_id = typeId; // eslint-disable-line camelcase
        body.items = transformMandates(mandates);

        if (isExported) {
            body.exportedAt = (new Date()).toISOString().substr(0, 10);
        }

        try {
            body = JSON.stringify(body);
            requestOptions.body = body;
        } catch (e) {
            return Promise.reject(e);
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.all), requestOptions)
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Adds mandates to an already created export
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - Export ID to fetch
     * @param {object[]|object} mandates - Mandates to add to this export
     * @param {number} mandates.mandateId - The mandate's unique ID
     * @param {string} mandates.subject - The subject for this transaction
     * @param {string|number} mandates.amount - The amount of this transaction
     *
     * @return {Promise.<SepaRequest.export.Export|Error>}
     */
    function addMandates(exportId, mandates) {
        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        if (!Array.isArray(mandates)) {
            mandates = [mandates];
        }

        let rejectMessages = validateMandates(mandates),
            requestOptions = helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'PATCH'),
            body;

        if (rejectMessages.length) {
            return Promise.reject(new Error(rejectMessages.join(', ')));
        }

        try {
            body = JSON.stringify({items: transformMandates(mandates)});
            requestOptions.body = body;
        } catch (e) {
            return Promise.reject(e);
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {exportId}), requestOptions)
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Mark a given export as exported
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - Export ID to update
     * @param {string} [exportedAt] - If omitted, use current date as export date, otherwise specify a date in format "YYYY-MM-DD"
     *
     * @return {Promise.<SepaRequest.export.Export|Error>}
     */
    function setExported(exportId, exportedAt) {
        let requestOptions = helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'PATCH'),
            body;

        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        if (!exportedAt) {
            exportedAt = (new Date()).toISOString().substr(0, 10);
        }

        try {
            body = JSON.stringify({exportedAt});
            requestOptions.body = body;
        } catch (e) {
            return Promise.reject(e);
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {exportId}), requestOptions)
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Adds a history entry for a given export<br />
     * Shouldn't be used directly
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - Export ID to update
     * @param {number} statusId - Add this status id to the history
     * @return {*}
     */
    function status(exportId, statusId) {
        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        if (typeof statusId === 'undefined') {
            return Promise.reject(new Error('Missing statusId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.status, {exportId, statusId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Downloads the XML for a given export id.<br />
     * Useless in browser environments
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - The export's unique ID
     * @param {string} iban - XML creator's iban
     * @param {string} accountHolder - XML creator's name
     * @param {string} [bic = ''] - XML creator's bic, only needed for foreign transactions
     *
     * @return {Promise.<Blob|Error>}
     */
    function xml(exportId, iban, accountHolder, bic = '') {
        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        if (typeof iban === 'undefined') {
            return Promise.reject(new Error('Missing iban parameter'));
        }

        if (typeof accountHolder === 'undefined') {
            return Promise.reject(new Error('Missing accountHolder parameter'));
        }

        let url = helpers.buildUrl(options.baseUrl, ROUTES.xml, {exportId, iban, accountHolder});
        if (bic !== '') {
            url += '&bic=' + bic;
        }

        return fetch(url, helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus);
    }

    /**
     * Returns the XML download link for a given export id
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - The export's unique ID
     * @param {string} iban - XML creator's iban
     * @param {string} accountHolder - XML creator's name
     * @param {string} [bic = ''] - XML creator's bic, only needed for foreign transactions
     *
     * @return {string}
     */
    function xmlUrl(exportId, iban, accountHolder, bic = '') {
        if (typeof exportId === 'undefined') {
            throw new Error('Missing exportId parameter');
        }

        if (typeof iban === 'undefined') {
            throw new Error('Missing iban parameter');
        }

        if (typeof accountHolder === 'undefined') {
            throw new Error('Missing accountHolder parameter');
        }

        let url = helpers.buildUrl(options.baseUrl, ROUTES.xml, {exportId, iban, accountHolder});
        if (bic !== '') {
            url += '&bic=' + bic;
        }

        return url;
    }

    /**
     * Downloads a test CSV for a given export id.<br />
     * Useless in browser environments
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - The export's unique ID
     *
     * @return {Promise.<Blob|Error>}
     */
    function csv(exportId) {
        if (typeof exportId === 'undefined') {
            return Promise.reject(new Error('Missing exportId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.csv, {exportId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus);
    }

    /**
     * Returns the test CSVdownload link for a given export id
     *
     * @memberOf SepaRequest.export
     * @param {number} exportId - The export's unique ID
     *
     * @return {string}
     */
    function csvUrl(exportId) {
        if (typeof exportId === 'undefined') {
            throw new Error('Missing exportId parameter');
        }

        return helpers.buildUrl(options.baseUrl, ROUTES.csv, {exportId});
    }

    /**
     * Check if mandates are valid
     *
     * @private
     * @memberOf SepaRequest.export
     * @param {object[]} mandates - See {@link SepaRequest.export.create} for signature of mandates
     *
     * @return {Array} - An array of error messages
     */
    function validateMandates(mandates) {
        let rejectMessages = [];

        mandates.forEach(mandate => {
            try {
                if (!(mandate instanceof Object)) {
                    rejectMessages.push(`Invalid mandate: ${JSON.stringify(mandate)}`);
                    return;
                }

                if (!mandate.mandateId || !mandate.subject || !mandate.amount) {
                    rejectMessages.push(`MandateId, subject or amount needed: ${JSON.stringify(mandate)}`);
                    return;
                }

                let amount = typeof mandate.amount === 'string' ? mandate.amount.replace(',', '.') : mandate.amount;
                if (isNaN(parseFloat(amount))) {
                    rejectMessages.push(`Invalid amount: ${JSON.stringify(mandate)}`);
                }
            } catch (e) {
                rejectMessages.push(e.message);
            }
        });

        return rejectMessages;
    }

    /**
     * Transform a collection of mandates into the format required by the webservice
     *
     * @private
     * @memberOf SepaRequest.export
     * @param {object[]} mandates - See {@link SepaRequest.export.create} for signature of mandates
     *
     * @return {Array} - Transformed input
     */
    function transformMandates(mandates) {
        return mandates.map(mandate => {
            mandate.mandate_id = mandate.mandateId; // eslint-disable-line camelcase
            return mandate;
        });
    }

    return {
        fetchAll: fetchAll,
        fetch: fetchOne,
        create: create,
        addMandates: addMandates,
        setExported: setExported,
        status: status,
        xml: xml,
        xmlUrl: xmlUrl,
        csv: csv,
        csvUrl: csvUrl,
        EXPORT_TYPES: EXPORT_TYPES
    };
}

module.exports = sepaExport;
