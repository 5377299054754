'use strict';

/**
 * @namespace helpers
 * @memberOf SepaRequest
 */

/**
 * Checks if a response has an error or ok HTTP Status and throws accordingly
 *
 * @memberOf SepaRequest.helpers
 * @param {Response} response - An http Response
 * @return {Response|Error}
 */
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    let error = new Error(response.statusText);
    error.response = response;
    throw error;
}

/**
 * Helper function that extracts a json body from a response
 *
 * @memberOf SepaRequest.helpers
 * @param {Response} response - An http response
 * @return {object}
 */
function parseJson(response) {
    return response.json();
}

/**
 * Builds a url from a given route template
 *
 * @example
 * // return http://my-url/foo/1/bar/baz
 * buildUrl('my-url', 'foo/:param1/bar/:someParam', {param1: 1, someParam: 'baz'})
 *
 * @memberOf SepaRequest.helpers
 * @param {string} baseUrl -The webservice's base url
 * @param {string} [route = ''] - The route to be used as a template
 * @param {object} [params = {}] - Route parameters
 *
 * @return {string}
 */
function buildUrl(baseUrl, route = '', params = {}) {
    var base = baseUrl[baseUrl.length - 1] === '/' ? baseUrl : baseUrl + '/';

    Object.keys(params).forEach(param => {
        route = route.replace(new RegExp(':' + param, 'g'), params[param]);
    });

    return base + route;
}

/**
 * Builds fetch API request options
 * @param {string} apiUser - Api User
 * @param {number|string} apiAgent - Api Agent
 * @param {string} apiToken - Api Token
 * @param {string} [method = 'GET'] - HTTP method
 * @return {{method: string, headers: object}}
 */
function getRequestOptions(apiUser, apiAgent, apiToken, method = 'GET') {
    return {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'API-USER': apiAgent,
            'API-AGENT': apiAgent,
            'API-TOKEN': apiToken
        }
    };
}

module.exports = {
    checkStatus: checkStatus,
    parseJson: parseJson,
    buildUrl: buildUrl,
    getRequestOptions: getRequestOptions
};
