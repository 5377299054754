'use strict';

require('whatwg-fetch');
require('es6-promise').polyfill();

const helpers = require('./helpers'),

    /**
     * Enum specifying mandate types
     * @enum {number}
     * @readonly
     * @memberOf SepaRequest.mandate
     */
    TYPES = {

        /**
         * The mandate is a single mandate
         */
        SINGLE_MANDATE: 1,

        /**
         * The mandate is a recurring mandate
         */
        RECURRING_MANDATE: 2
    },

    /**
     * Enum specifying the available update methods a mandate can be patched with
     * @enum {string}
     * @readonly
     * @memberOf SepaRequest.mandate
     */
    UPDATE_OPTIONS = {

        /**
         * Mandate is marked as 'signed'
         */
        SIGNED: 'signed',

        /**
         * Mandate is marked as 'used'
         */
        USAGE: 'usage',

        /**
         * Mandate is marked as 'being revoked'
         */
        REVOKE: 'revoke'
    },
    ROUTES = {
        all: 'mandate',
        one: 'mandate/:mandateId',
        validate: 'mandate/validate/:customerId/:iban/:bic/:quantity',
        pdf: 'mandate/:mandateId/pdf'
    };

/**
 * Methods to interact with the SEPA api's mandate endpoint
 *
 * @namespace mandate
 * @memberOf SepaRequest
 * @param {object} options - WIll automatically be applied
 *
 * @return {object}
 */
function mandate(options) {

    /**
     * A collection of mandates
     *
     * @typedef {object} SepaRequest.mandate.MandateCollection
     * @property {object} links - HATEOAS links
     * @property {object} links.self
     * @property {string} links.self.href - Link to this document
     * @property {object} links.first
     * @property {string} links.first.href - Link to the first page of a paginated response
     * @property {object} links.last
     * @property {string} links.last.href - Link to the last page of a paginated response
     * @property {object} _embedded
     * @property {array.<SepaRequest.mandate.Mandate>} _embedded.mandate - A collection of mandates
     * @property {number} _embedded.page_count - How many pages there are for a paginated result
     * @property {number} _embedded.page_size - How many mandates per page are returned
     * @property {number} _embedded.total_items -  The total amount of mandates that are available
     * @property {number} _embedded.page - The page this document referes to
     */

    /**
     * A mandate as returned from the sepa webservice
     *
     * @typedef {object} SepaRequest.mandate.Mandate
     * @property {number} id - Mandate's unique database id
     * @property {number} customerId - The customer id this mandate belongs to
     * @property {number} accountId - The customer's account id this mandate belongs to
     * @property {SepaRequest.Agent} agent - The agent that created this mandate
     * @property {object} type - Information about which type of mandate this is
     * @property {SepaRequest.mandate.TYPES} type.id - The type's unique identifier
     * @property {string} type.name - The type's name
     * @property {string} uniqueMandateReference - Auto generated mandate reference
     * @property {string} iban - The iban associated to this mandate
     * @property {string} bic - The bic associated to this mandate
     * @property {string} accountHolder - Account holder's name this mandate was created for
     * @property {boolean} valid - Is this mandate valid
     * @property {string} validTo - Date until this mandate is valid, format "YYYY-MM-DD"
     * @property {array.<SepaRequest.mandate.HistoryEntry>} history - The history of what was done with this mandate
     * @property {object} links - HATEOAS links
     * @property {object} links.self
     * @property {string} links.self.href - Link to this mandate
     * @property {object} links.pdf
     * @property {string} links.pdf.href - Download link for this mandate's PDF
     */

    /**
     * A mandates history
     *
     * @typedef {object} SepaRequest.mandate.HistoryEntry
     * @property {SepaRequest.mandate.UPDATE_OPTIONS} type - What was done with this mandate
     * @property {date} timestamp - A timestamp for when this history entry was created
     */

    /**
     * Fetches a paginated list of all mandates
     *
     * @memberOf SepaRequest.mandate
     * @param {number} [accountId = 0] - If given only fetch mandates for this account id
     * @param {number} [page = 1] - Pagination page
     *
     * @return {Promise.<SepaRequest.mandate.MandateCollection|Error>}
     */
    function fetchAll(accountId = 0, page = 1) {
        let query = '';

        if (accountId) {
            query = '?accountId=' + accountId;
        }

        if (page !== 1) {
            query += query === '' ? '?page=' + page : '&page=' + page;
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.all) + query, helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Fetches a single mandate by given id
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - Mandate ID to fetch
     *
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function fetchOne(mandateId) {
        if (typeof mandateId === 'undefined') {
            return Promise.reject(new Error('Missing mandateId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {mandateId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Creates a new mandate
     *
     * @memberOf SepaRequest.mandate
     * @param {object} [postData = {}] - Customer data for which a new mandate is being created
     * @param {number} postData.customerId - Customer ID the mandate is being created for
     * @param {number} postData.accountId - The customer's account ID the mandate is being created for
     * @param {object} postData.type
     * @param {SepaRequest.mandate.TYPES} postData.type .id - Type of this mandate
     * @param {string} postData.iban - The customer's IBAN the mandate is being created for
     * @param {string} [postData.bic = 0] - The customer's BIC the mandate is being created for
     * @param {string} postData.accountHolder - The customer's name the mandate is being created for
     *
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function create(postData = {}) {
        let rejectMessages = [],
            data = postData,
            requestOptions = helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'POST'),
            body;


        if (!(postData instanceof Object) || Object.keys(postData).length === 0) {
            return Promise.reject(new Error('Missing postData parameter'));
        }

        ['customerId', 'accountId', 'type', 'iban', 'accountHolder'].forEach(requiredParam => {
            if (!postData[requiredParam]) {
                rejectMessages.push(`Missing postData.${requiredParam} parameter`);
            }
        });

        if (rejectMessages.length) {
            return Promise.reject(new Error(rejectMessages.join(', ')));
        }

        data.agent = options.agent;
        data.bic = data.bic || 0;

        try {
            body = JSON.stringify(data);
            requestOptions.body = body;
        } catch (e) {
            return Promise.reject(e) ;
        }


        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.all), requestOptions)
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Update a single mandate
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     * @param {SepaRequest.mandate.UPDATE_OPTIONS[]} [updateOptions=[]] - An array of updates which should be applied to this mandate
     *
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function update(mandateId, updateOptions = []) {
        let allowedUpdateOptions = [UPDATE_OPTIONS.REVOKE, UPDATE_OPTIONS.SIGNED, UPDATE_OPTIONS.USAGE],
            disallowedOption = '',
            requestOptions = helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'PATCH');

        if (typeof mandateId === 'undefined') {
            return Promise.reject(new Error('Missing mandateId parameter'));
        }

        if (!updateOptions.length) {
            return Promise.reject(new Error('Missing an updateOption'));
        }

        updateOptions.forEach(option => {
            if (allowedUpdateOptions.indexOf(option) === -1) {
                disallowedOption = option;
            }
        });

        if (disallowedOption !== '') {
            return Promise.reject(new Error(`Update option ${disallowedOption} not allowed.`));
        }

        requestOptions.body = JSON.stringify({history: updateOptions});

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {mandateId}), requestOptions)
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Shorthand method that calls {@link SepaRequest.mandate.update} with a 'signed' update
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function signMandate(mandateId) { return update(mandateId, [UPDATE_OPTIONS.SIGNED]); }

    /**
     * Shorthand method that calls {@link SepaRequest.mandate.update} with a 'revoke' update
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function revokeMandate(mandateId) { return update(mandateId, [UPDATE_OPTIONS.REVOKE]); }

    /**
     * Shorthand method that calls {@link SepaRequest.mandate.update} with a 'usage' update
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     * @return {Promise.<SepaRequest.mandate.Mandate|Error>}
     */
    function useMandate(mandateId) { return update(mandateId, [UPDATE_OPTIONS.USAGE]); }

    /**
     * Deletes a single mandate
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     *
     * @return {Promise.<void|Error>}
     */
    function remove(mandateId) {
        if (typeof mandateId === 'undefined') {
            return Promise.reject(new Error('Missing mandateId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.one, {mandateId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'DELETE'))
            .then(helpers.checkStatus);
    }

    /**
     * Deletes all mandates
     *
     * @memberOf SepaRequest.mandate
     *
     * @return {Promise.<void|Error>}
     */
    function removeAll() {
        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.all), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken, 'DELETE'))
            .then(helpers.checkStatus);
    }

    /**
     * Check if a customer has a valid mandate
     *
     * @memberOf SepaRequest.mandate
     * @param {number} customerId - The customer's unique ID
     * @param {string} iban - The customer's IBAN for which a mandate should be checked
     * @param {string} [bic = 0] - The customer's BIC for which a mandate should be checked
     * @param {number} [quantity = 1] - Check if the mandate, if exists is valid for one or multiple transactions
     *
     * @return {Promise.<SepaRequest.mandate.Mandate|{valid: boolean, mandate: boolean}>}
     */
    function validate(customerId, iban, bic = 0, quantity = 0) {
        if (typeof customerId === 'undefined') {
            return Promise.reject(new Error('Missing customerId parameter'));
        }

        if (typeof iban === 'undefined') {
            return Promise.reject(new Error('Missing iban parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.validate, {customerId, iban, bic, quantity}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus)
            .then(helpers.parseJson);
    }

    /**
     * Downloads the PDF for a given mandate id.<br />
     * Useless in browser environments
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     *
     * @return {Promise.<Blob|Error>}
     */
    function pdf(mandateId) {
        if (typeof mandateId === 'undefined') {
            return Promise.reject(new Error('Missing mandateId parameter'));
        }

        return fetch(helpers.buildUrl(options.baseUrl, ROUTES.pdf, {mandateId}), helpers.getRequestOptions(options.apiUser, options.agent.id, options.apiToken))
            .then(helpers.checkStatus);
    }

    /**
     * Returns the download link for a given mandate id
     *
     * @memberOf SepaRequest.mandate
     * @param {number} mandateId - The mandate's unique ID
     *
     * @return {string}
     */
    function pdfUrl(mandateId) {
        if (typeof mandateId === 'undefined') {
            throw new Error('Missing mandateId parameter');
        }

        return helpers.buildUrl(options.baseUrl, ROUTES.pdf, {mandateId});
    }

    return {
        fetchAll: fetchAll,
        fetch: fetchOne,
        create: create,
        update: update,
        signMandate: signMandate,
        useMandate: useMandate,
        revokeMandate: revokeMandate,
        remove: remove,
        removeAll: removeAll,
        validate: validate,
        pdf: pdf,
        pdfUrl: pdfUrl,
        TYPES: TYPES,
        UPDATE_OPTIONS: UPDATE_OPTIONS
    };
}

module.exports = mandate;
